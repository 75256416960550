<template>
  <div id="codefilling-form">
    <div class=" codefilling-inner-row" id="form">
      <!-- FORM -->
      <validation-observer
          v-if="formStatus === false && !maximumUploads && !winnerMessage"
          v-slot="{ handleSubmit }"
          tag="div"
      >
        <form
            id="promotionCodeForm"
            class="form form--codefilling row"
            @submit.prevent="handleSubmit(formSubmit)">
            <div class="col-md-12 col-lg-10 offset-lg-1">
                <div class="codefilling-form codefilling-form--inner-wrap" style="background: white;">
                    <h1 class="mb-4 text-center codefilling-form-title">Kódfeltöltés</h1>
                    <div class="form-subtitle"></div>
                    <div class="row">
                        <div class="col-12 col-lg-6 order-1 order-lg-2">
                            <div class="p-4">
                                <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules }" tag="div" class="mb-3 position-relative">
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="A játékos személyi igazolványába jegyzett vezetéknév"
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label">Vezetéknév<span class="ml-1 error">*</span></div>
                                    <input
                                        v-model="form.surname"
                                        type="text"
                                        class="form-control"
                                        name="Vezetéknév"
                                        placeholder="pl. Molnár"
                                        id="surname"
                                    />
                                    <span class="error">{{
                                        getErrorMessage("surname", Object.entries(failedRules)[0])
                                    }}</span>
                                </ValidationProvider>
                                <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules }" tag="div" class="mb-3 position-relative">
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="A játékos személyi igazolványába jegyzett keresztnév"
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label">Keresztnév<span class="ml-1 error">*</span></div>
                                    <input
                                        v-model="form.forename"
                                        type="text"
                                        placeholder="pl. Katalin"
                                        class="form-control"
                                        name="Keresztnév"
                                        id="forname"
                                    />
                                    <span class="error">{{
                                        getErrorMessage("forname", Object.entries(failedRules)[0])
                                    }}</span>
                                </ValidationProvider>
                                <ValidationProvider
                                    rules="email|required"
                                    v-slot="{ failedRules }"
                                    vid="confirmationEmail"
                                    tag="div" class="mb-3 position-relative"
                                >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="A játékos e-mail címe"
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label">E-mail cím<span class="ml-1 error">*</span></div>
                                    <input
                                        v-model="form.email"
                                        type="text"
                                        placeholder="pl. molnar.katalin@gmail.com"
                                        class="form-control"
                                        name="E-mail cím"
                                        id="email"
                                        ref="emailAddress"
                                    />
                                    <span class="error">{{
                                        getErrorMessage("email", Object.entries(failedRules)[0])
                                    }}</span>
                                </ValidationProvider>
                                <ValidationProvider
                                    rules="required|confirmed:confirmationEmail"
                                    v-slot="{ failedRules }"
                                    tag="div" class="mb-3 position-relative"
                                >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="A játékos e-mail címe még egyszer"
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label">E-mail cím még egyszer<span class="ml-1 error">*</span></div>
                                    <input
                                        v-model="form.emailConfirm"
                                        type="text"
                                        placeholder="pl. molnar.katalin@gmail.com"
                                        class="form-control"
                                        name="E-mail cím még egyszer"
                                        id="email-confirm"
                                    />
                                    <span class="error">{{
                                        getErrorMessage(
                                            "emailConfirm",
                                            Object.entries(failedRules)[0]
                                        )
                                    }}</span>
                                </ValidationProvider>
                                <ValidationProvider class="mb-3 position-relative" tag="div">
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Telefonszám, amin a futár elér."
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label">Telefonszám</div>
                                    <input
                                        v-mask="'+36-##-###-####'"
                                        v-model="form.phone"
                                        type="text"
                                        class="form-control"
                                        name="Telefonszam"
                                        id="phone"
                                        placeholder="+36__-___-____"
                                    />

                                </ValidationProvider>
                                <ValidationProvider
                                    rules=""
                                    v-slot="{ failedRules }"
                                    class="position-relative" tag="div">
                        <!--                    <span class="apText" style="display: none">APA-</span>-->
                                    <div class="form-label">Hol vásároltad a terméket?</div>
                                    <div class="d-flex flex-row flex-wrap where_bought-row" style="flex: 50%;">
                                        <div v-for="item in shops" :key="item" class="d-flex align-items-center" style="flex:50%;">
                                            <input
                                                v-model="form.where_bought" 
                                                type="radio"
                                                class="form-control text-uppercase radio-btn"
                                                :id="item"
                                                :value="item"
                                            />
                                            <label class="m-0 ml-2" :for="item"> {{ item }} </label>
                                        </div>
                                    </div>
                                    <span class="error">{{
                                        getErrorMessage("shops", Object.entries(failedRules)[0])
                                    }}</span>
                                </ValidationProvider>

                                <input type="text" v-model="where_bought_other" :disabled="form.where_bought != 'Egyéb'" class="w-100 ml-2" style="height:30px;font-size:14px;"/>
                                        
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 order-2 order-lg-1">
                            <div class="p-4" style="background: #179546;border-radius:4px;">
                                <ValidationProvider
                                    :rules="{ min: 6, required: true }"
                                    v-slot="{ failedRules }"
                                    class="position-relative" tag="div">
                        <!--                    <span class="apText" style="display: none">APA-</span>-->
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="A nyitófülön, kupakban vagy kuponon található 6 vagy 7 jegyű kód."
                                    ><div class="tooltip-arrow"></div></span>
                                    <div class="form-label text-white">Promóciós kód<span class="ml-1 error">*</span></div>
                                    <input
                                        maxlength="7"
                                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        @focus="setAPInput"
                                        @blur="foucOut"
                                        v-model="form.code"
                                        type="text"
                                        class="form-control text-uppercase"
                                        name="AP-kód"
                                        id="ap-code"
                                        placeholder="______"
                                        @keyup="uppercase"
                                    />
                                    <span class="error">{{
                                        getErrorMessage("apCode", Object.entries(failedRules)[0])
                                    }}</span>
                                </ValidationProvider>
                                <img src="@/assets/img/form/code.png" class="img-fluid d-none d-lg-block">
                                <img src="@/assets/img/form/code-mobile.png" class="img-fluid d-block d-lg-none">
                            </div>
                            <div class="upload-text mt-3"><span class="ml-1 error">*</span> kötelezően kitöltendő mező</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-10 offset-lg-1 ">
            <div class="upload-bg">
              <div class="col-12 winner-codefilling__text ">

                <div class="custom-control custom-checkbox checkbox-margin condition-row">
                  <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules }"
                      tag="div"
                  >
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="conditions"
                        name="Játékszabályzat"
                    />
                    <label
                        class="custom-control-label mb-0 condition-label"
                        for="conditions"
                    >

                      <div>

                        Kijelentem, hogy 18 éves elmúltam, a<a
                          :href="
                            baseUrl+'documents/Jatekszabalyzat.pdf?' +
                            jatekszabalyTimeStamp
                          "
                          target="_blank"
                      >
                        <span class="">Játékszabályzatot</span></a
                      >elolvastam, annak feltételeit elfogadom, és az
                        adatkezeléssel kapcsolatos, a 14. pontban foglalt
                        tájékoztatást kifejezetten tudomásul vettem. <span
                          style="text-decoration:none; color: red;"><strong>*</strong></span>
                      </div>

                    </label>
                    <div class="mt-4"></div>
                    <span class="error">{{
                        getErrorMessage("condition", Object.entries(failedRules)[0])
                      }}</span>
                  </ValidationProvider>
                </div>
                <ValidationProvider
                    rules="required|acceptConditions"
                    v-slot="{ failedRules }"
                    tag="div"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.consent"
                        type="checkbox"
                        class="custom-control-input"
                        id="newsletter"
                        name="Nyertesek nevének közzététele"
                    />
                    <label class="custom-control-label mb-0 condition-label" for="newsletter">
                      <div>
                        Hozzájárulok ahhoz, hogy a nyertesként történő kisorsolásom esetén a szervező a vezetéknevem kezdőbetűjét és a keresztnevemet, a nyerés tényét, valamint a nyeremény megjelölését az alábbi honlapon közzé tegye: h150.heineken.hu/kodfeltoltes. Az adatkezelésre vonatkozó részletes információkat a hivatalos játékszabályzat 14. pontja tartalmazza.Hozzájárulok ahhoz, hogy a nyertesként történő kisorsolásom esetén a szervező a vezetéknevem kezdőbetűjét és a keresztnevemet, a nyerés tényét, valamint a nyeremény megjelölését az alábbi honlapon közzé tegye: h150.heineken.hu/kodfeltoltes. Az adatkezelésre vonatkozó részletes információkat a hivatalos játékszabályzat 14. pontja tartalmazza.<span
                          style="text-decoration:none; color: red;"><strong>*</strong></span>
                      </div>
                    </label>
                    <span class="error">{{
                        getErrorMessage("marketing", Object.entries(failedRules)[0])
                      }}</span>
                    <div class="mt-4"></div>
                  </div>
                </ValidationProvider>


                <div class="custom-control custom-checkbox">
                  <input
                      true-value="1"
                      false-value="0"
                      v-model="form.marketing"
                      type="checkbox"
                      class="custom-control-input"
                      id="consent"
                      name="Nyertesek nevének közzététele"
                  />
                  <label class="custom-control-label" for="consent">
                    <div>
                      Résztvevő  előzetesen egyértelműen és kifejezetten hozzájárult ahhoz, hogy az Adatkezelő közvetlen marketing célú megkereséseket küldjön a részére, például email-ben, vagy egyéb módon (opt-in). 
                    </div>
                  </label>

                </div>

                <ValidationProvider
                    v-if="form.marketing === '1'"
                    class="mb-4 col-12 birthDate--marketing"
                    rules="required|isOver18"
                    v-slot="{ failedRules }"
                    tag="div"
                >
                  <div class="form-label">Kérlek add meg a születési dátumod.<span class="ml-1 error">*</span></div>
                  <input
                      v-mask="'####-##-##'"
                      v-model="form.birthDate"
                      type="text"
                      placeholder="0000-00-00"
                      class="form-control"
                      name="Keresztnév"
                      id="birthDate"
                  />
                  <span class="error">{{
                      getErrorMessage("birthDate", Object.entries(failedRules)[0])
                    }}</span>
                  <span v-if="!overEighteen" class="error">
                    A feliratkozáshoz be kell töltened a 18. életévedet.
                  </span>
                </ValidationProvider>

                <div class="text-center mt-3 send-form-btn">
                  <button type="submit" class="btn btn-modal-first">
                    feltöltöm a kódom
                  </button>
                </div>
              </div>
            </div>
          </div>


        </form>
      </validation-observer>
      <div
          v-if="winnerMessage"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div
            class="codefilling-form  codefilling-form--inner-wrap border-radius-message-card form-error-message ">
          <div
              v-if="winnerMessage" class="col-12 m-auto text-center order-nyertes p-0"
          >
            <div class="main-h1-notwin  mt-0">GRATULÁLUNK!</div>
            <div class="text mb-3">
              <div class="nem-nyert-img-wrap">
                <img src="@/assets/img/form/sikeres.png" class="img-fluid nem-nyert-img"/>
              </div>

              <div class="text-red">NYERT! nyitófül esetén azonnali<br/> nyereményed 1 korsó csapolt Soproni.</div>
              <!--              <div class="text-red">Mit kell tennie ezután?</div>-->
              <p>
                Keresd a beváltóhelyeket az alábbi térképen! </p>
            </div>
            <div class="row ml-auto justify-content-center mr-auto mt-4">
              <div class="btn-wrap justify-content-center m-md-1">
                <button class="mt-0  btn btn-primary btn-primary--large"
                        @click="backToForm(); setGTM('nyert_landing','nyert_landing','gomb_kattintas','ujabb_kodot_toltok_fel')">
                  Újabb kódot töltök fel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- OTHER SCREENS -->
      <div
          v-if="maximumUploads"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div
            class="codefilling-form  codefilling-form--inner-wrap form-error-message ">
          <div
              class="col-12 m-auto text-center order-nyertes"
              v-if="maximumUploads"
          >
            <!-- Error message - maximum feltöltés -->
            <div class="main-h1-notwin mt-0">Sikertelen kódfeltöltés!</div>
            <div class="winning-img--error">
              <img class="img-fluid" src="@/assets/img/form/sikertelen.png" alt=""/>
            </div>
            <p class="text-white fw-bold" style="font-size: 18px;">
              Naponta maximum 3 kódfeltöltésre van lehetőség. Próbálkozz újra holnap. </p>
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-primary btn-primary--large"
                      @click="backToForm();setGTM('maximum_feltoltes_landing','maximum_feltoltes_landing','gomb_kattintas','fooldal')">
                FŐOLDAL
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
          v-if="formStatus === true"
          class="form form--status p-0 col-12 col-md-10 offset-md-1 offset-lg-1 col-lg-6 offset-lg-3"
      >
        <div v-if="!maximumUploads"
             class="codefilling-form  codefilling-form--inner-wrap form-error-message ">
          <div
              class="col-12 m-auto text-center order-nyertes p-0"
              v-if="(uploadedError === true || alreadyUploadedCode) && !maximumUploads"
          >
            <!-- SIKERTELEN BEKÜLDÉS - érvénytelen AP kód -->
            <div class="main-h1-notwin mt-0">Sikertelen kódfeltöltés</div>
            <div class="winning-img--error">
              <img src="@/assets/img/form/sikertelen.png" class="img-fluid" alt=""/>
            </div>
            <!--                        <div class="text-red">Mit kell tennie ezután?</div>-->
            <p class="email-info-text--small puls-padding-apcode">
              Lehet, hogy elütöttél valamit, vagy ezzel a kóddal már játszottál.
              Ellenőrizd a kódot, és próbáld meg újra!</p>
            <p class="text-white fw-bold" style="font-size: 18px;">Figyelem! Naponta maximum 3 kódfeltöltésre van lehetőség!</p>
            <div class="btn-wrap mt-md-4 m-md-1">
              <button class="btn btn-primary btn-primary--large"
                      @click="backToForm();setGTM('sikertelen_landing','sikertelen_landing','gomb_kattintas','megprobalom_ujra')">
                megpróbálom újra
              </button>
            </div>
          </div>


          <!-- SIKERES BEKÜLDÉS -->
          <div
              v-if="!winner && !uploadedError && !alreadyUploadedCode && !maximumUploads"
              class="col-12 m-auto text-center order-nyertes p-0"
          >
            <div class="main-h1-notwin  mt-0">Sikeres kódfeltöltés</div>
            <div class="text mb-3">
              <div class="nem-nyert-img-wrap">
                <img src="@/assets/img/form/sikeres.png" class="img-fluid nem-nyert-img"/>
              </div>


              <div class="text-red">
                Mit kell tenned ezután?
              </div>
              <p>
                Figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyerteseket
                e-mailben értesítjük!
              </p>
            </div>
            <div class="row ml-auto justify-content-center mr-auto mt-4">
              <div class="btn-wrap justify-content-center m-md-1">
                <button class="mt-0  btn btn-primary btn-primary--large"
                        @click="backToForm();setGTM('sikeres_landing','sikeres_landing','gomb_kattintas','ujabb_kodot_toltok_fel')">
                  Újabb kódot töltök fel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import axios from "axios";
import $ from "jquery";
/* eslint-disable */
import {ValidationObserver} from "vee-validate";
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";
import {required, email, regex, confirmed} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";

// Import date picker css

setInteractionMode("passive");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Legalább 2 karakter szükséges.",
});
extend("minAz", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Az azonosító szám formátuma nem megfelelő.",
});
extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "A Játékszabályzatot kötelező elfogadnia. ",
});

//18 elmúlt
extend("isOver18", {
  validate(value) {
    let date = value.split('-')
    console.log(date)
    let birthDateY = parseInt(date[0]);
    let birthDateM = parseInt(date[1]);
    let birthDateD = parseInt(date[2]);

    var birthdate = new Date();
    birthdate.setFullYear(birthDateY, birthDateM - 1, birthDateD);

    var currdate = new Date();
    currdate.setFullYear(currdate.getFullYear() - 18);

    console.log('setfullyear', currdate, birthdate, (currdate - birthdate))

    if ((currdate - birthdate) < 0) {
      return false;
    } else {
      return true;
    }
  },
  message: "Csak 18 év felett lehetséges hírlevélre feliratkozni ",
});

extend("email", {
  ...email,
  message: "Az e-mail mező csak érvényes e-mail címet tartalmazhat.",
});
extend("required", {
  ...required,
  message: "{_field_} megadása kötelező.",
});
extend("confirmed", {
  ...confirmed,
  message: "Nem megegyező email cím.",
});

export default {
  data() {
    const date = new Date();
    const purchaseDate = `${this.dateValue} + ${this.timeValue}`
    return {
      dateValue: '',
      timeValue: '',
      formatted: '',
      selected: '',
      locale: 'hu',
      weekday: 1,
      context: {
        "hourCycle": "h23",
        "locale": "hu"
      },
      where_bought_other: "",
      shops: [
        "Tesco","Spar/Interspar","Auchan","Aldi", "CBA","Lidl", "Penny Market", "Vendéglátóhely", "Egyéb"
      ],
      labels: {
        hu: {
          labelPrevDecade: 'Előző évtized',
          labelPrevYear: 'Előző év',
          labelPrevMonth: 'Előző hónap',
          labelCurrentMonth: 'Jelenlegi hónap',
          labelNextMonth: 'Következő hónap',
          labelNextYear: 'Következő év',
          labelNextDecade: 'A következő évtized',
          labelToday: 'Ma',
          labelSelected: 'Kiválasztott dátum',
          labelNoDateSelected: 'Nincs dátum kiválasztva',
          labelCalendar: 'Naptár',
          labelNav: 'Naptár navigáció',
          labelHelp: 'A nyílbillentyűkkel navigálhat a naptárban',
          labelHours: 'órák',
          labelMinutes: 'percek',
          labelSeconds: 'másodpercek',
          labelIncrement: 'emel',
          labelDecrement: 'Csökkent',
          labelNoTimeSelected: 'Nincs kiválasztva az idő',
          labelCloseButton: 'Bezárás'
        }
      },
      show: false,
      codeUpperCase: "",
      form: {
        code: "",
        phone: "",
        surname: "",
        forename: "",
        purchase_date: "",
        email: "",
        emailConfirm: "",
        condition: "",
        marketing: "",
        consent: "",
        birthDate: '',
        where_bought: "",
        receipt: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,
      },
      overEighteen: true,
      winnerMessage: false,
      responseErrors: null,
      uploadedError: false,
      alreadyUploadedCode: false,
      hash: undefined,
      winType: undefined,
      winner: undefined,
      formIsSubmitting: false,
      maximumUploads: false,
      imageError: false,
      uploadedFiles: [],
      formStatus: false,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  computed: {
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  watch: {
    formStatus(newData) {
      window.scroll(
          0,
          this.findPos(document.getElementById("codefilling-form")) - 76
      );

      this.$parent.backgroundChanger = newData
      // $(".purchase_date-row")
      //     .find("input")
      //     .attr("placeholder", "Vásárlás időpontja");
    },
  },
  methods: {
    setGTM(item, category, action, button) {
      let btnSucc = this.overEighteen ? 'sikeres' : 'sikertelen'

      this.GTtrackGA4({
        'event': 'event',
        'category': category,
        'action': button,
        'label': item,
        'button': 'kodfeltoltes',
        'clicked_text': item,
        'success': btnSucc
      })
    },
    uppercase() {
      this.form.code = this.form.code.toUpperCase();
    },
    // sub(handleSubmit, formSubmit, validate) {
    //   let _this = this;
    //   validate().then(success => {
    //     if (success) {
    //       handleSubmit(formSubmit);
    //     } else {
    //       $('span.error:not(.ml-1)').filter((e,f)=>f.textContent)[0].scrollIntoView()
    //       window.scrollBy(0, -200)
    //     }
    //     // else if (_this.form.condition !== "") {
    //     //   window.scroll(
    //     //       0,
    //     //       this.findPos(document.getElementById("codefilling-form")) - 76
    //     //   );
    //     // }
    //   })
    // },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
      this.context = ctx
    },
    passEvent() {
      this.$emit('formStatus', true)
    },
    handleFilesUpload() {
      let _this = this;
      //remove the error msg if there was
      this.$refs.uploadederror.innerHTML = "";

      let uploadedItem = this.$refs.receipt.files[0];

      //check file size 5Mb max
      let maxFileSize = 1024 * 1024 * 5;
      let fileSize = uploadedItem.size;

      //check file fomat
      // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

      //check file size - max 2Mb
      if (fileSize > maxFileSize) {
        this.$refs.uploadederror.innerHTML =
            "Túl nagy a fájl mérete (max. 5Mb)";
        // } else if (isGoodExtension == false) {
        //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
      } else {
        if (_this.uploadedFiles.length < 2) {
          //max 5 item could be uploaded

          let nameIndex = _this.uploadedFiles.find(
              (item) => item.name == uploadedItem.name
          );

          if (nameIndex == undefined) {
            _this.uploadedFiles.push(uploadedItem);
          } else {
            this.$refs.uploadederror.innerHTML = "Megegyező fájl név.";
          }
        } else {
          _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl.";
        }
      }

      this.fillReceipts();

      //set the default state for the input type=file
      this.$refs.receipt.value = ""

      if (this.form.receipt != null) {
        this.imageError = false;
      }
    },
    fillReceipts() {
      let _this = this;

      _this.form.receipt = null;
      _this.form.receipt2 = null;
      _this.form.receipt3 = null;
      _this.form.receipt4 = null;
      _this.form.receipt5 = null;

      this.uploadedFiles.forEach(function (item, i) {
        if (i == 0) {
          _this.form.receipt = item;
        } else if (i == 1) {
          _this.form.receipt2 = item;
        } else if (i == 2) {
          _this.form.receipt3 = item;
        } else if (i == 3) {
          _this.form.receipt4 = item;
        } else if (i == 4) {
          _this.form.receipt5 = item;
        }
      });
    },
    delUploadedFile(delItem) {
      let _this = this
      let delItemIndex = this.uploadedFiles.indexOf(delItem);

      if (delItemIndex != -1) {
        _this.uploadedFiles.splice(delItemIndex, 1);
      }

      this.$refs.uploadederror.innerHTML = "";
      this.fillReceipts();
    },
    removeError(fieldName) {
      $(`#promotionCodeForm .${fieldName}-row .error-2`).remove();
    },
    getErrorMessage(fieldName, violation) {
      return violation
          ? (errorMessages[fieldName] &&
              errorMessages[fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    setAPInput() {
      $(".apText").show();
      $(".apCode-wrap").find("input").addClass("focused");
      $(".apCode-wrap").find("input").removeAttr("placeholder");
    },
    foucOut() {
      if (this.form.apCode != 0) {
        $(".apText").show();
        $(".apCode-wrap").find("input").addClass("focused");
      } else {
        $(".apText").hide();
        $(".apCode-wrap").find("input").attr("placeholder", "AP-_________");
        $(".apCode-wrap").find("input").removeClass("focused");
      }
    },
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    },
    formSubmit() {
      let _this = this
      if (this.form.code === 'NYERT' || this.form.code === 'NYERT!') {
        _this.winnerMessage = true;
      } else {
        //check that the form was submitted
        if (!_this.formIsSubmitting) {
          _this.formIsSubmitting = true


        if(_this.form.where_bought === "Egyéb"){
            _this.form.where_bought = _this.where_bought_other;
        }
          let formData = new FormData();

          formData.append("surname", _this.form.surname);
          formData.append("forename", _this.form.forename);
          formData.append("birth_date", _this.form.birthDate);
          formData.append("code", _this.form.code);
          formData.append("phone", _this.form.phone);
          formData.append("email", _this.form.email);
          formData.append("condition", _this.form.condition);
          formData.append("purchase_date", `${_this.dateValue} ${_this.timeValue}`);
          formData.append("marketing", _this.form.marketing);
          formData.append("consent", _this.form.consent);
          formData.append("where_bought", _this.form.where_bought);
          // formData.append("receipt", _this.form.receipt);
          // formData.append("receipt2", _this.form.receipt2);
          // formData.append("receipt3", _this.form.receipt3);
          // formData.append("receipt4", _this.form.receipt4);
          // formData.append("receipt5", _this.form.receipt5);

          axios
              .post(process.env.VUE_APP_API_URL + 'step1/upload', formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                // console.log(response.data)

                if (response.data.status) {
                  var _this = this;

                  _this.GTtrackGA4({
                    'event': 'event',
                    'category': 'kodfeltoltes_blokk',
                    'action': 'kodfeltoltes_gomb_kattintas',
                    'label': 'sikeres',
                    'button': 'kodfeltoltes',
                    'clicked_text': 'kodfeltoltes',
                    'success': 'sikeres'
                  });
                  //show the win or not win block instead of the codefilling form
                  _this.formStatus = true;
                  _this.$store.state.codeMessage = true;
                  _this.passEvent();
                  // _this.winType = response.data.win_type;
                  // _this.hash = response.data.hash;
                  // _this.winner = !!response.data.winner;
                  _this.backToForm(true);
                } else {
                  $("#promotionCodeForm .form-subtitle").append(
                      '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                  );
                }
                _this.formIsSubmitting = false
              })
              .catch(function (error) {

                _this.GTtrackGA4({
                  'event': 'event',
                  'category': 'kodfeltoltes_blokk',
                  'action': 'kodfeltoltes_gomb_kattintas',
                  'label': 'sikertelen',
                  'button': 'kodfeltoltes',
                  'clicked_text': 'kodfeltoltes',
                  'success': 'sikertelen'
                });

                document.getElementById('promotionCodeForm').scrollIntoView(true);
                _this.formIsSubmitting = false
                console.log(error);
                if (!error.response.data.status) {
                  let errorsArray = Object.entries(error.response.data.error);

                  //remove the errors divs
                  if ($("#promotionCodeForm .error-2").length > 0) {
                    $("#promotionCodeForm .error-2").each(function () {
                      $(this).remove();
                    });
                  }
                  for (const [errorName, errorValue] of errorsArray) {
                    if (errorName == "code") {
                      //change the form block text
                      _this.formStatus = true;
                      _this.$store.state.codeMessage = true;
                      _this.uploadedError = true;
                      _this.alreadyUploadedCode = true;
                      _this.passEvent();
                      // } else if (errorName === "purchase_date") {
                      //   _this.alreadyUploadedCode = true;
                      //   _this.formStatus = true;
                    } else if (errorName == 'receipt' || errorName == 'receipt2' || errorName == 'receipt3' || errorName == 'receipt4' || errorName == 'receipt5') {
                      $("#promotionCodeForm .receipt-row").append(
                          '<div class="error-2">' + errorValue + "</div>"
                      );
                    } else if (errorName === "maximum_uploads") {
                      _this.maximumUploads = true;
                    } else if (errorName === "birth_date") {
                      _this.overEighteen = false
                    } else {
                      $("#promotionCodeForm ." + errorName + "-row").append(
                          '<div class="error-2">' + errorValue + "</div>"
                      );
                    }
                  }
                }
              });
        } else {
          console.log('double form submit')
        }


        // .catch(() => {
        //     console.log('Form submission cancelled');
        //   })
      }
    },
    backToForm(skipFormStatusReset) {
      this.form.surname = "";
      this.form.forename = "";
      this.form.code = "";
      this.form.birthDate = "";
      this.form.phone = "";
      this.form.purchase_date = "";
      this.form.email = "";
      this.form.emailConfirm = "";
      this.form.condition = "";
      this.form.marketing = "";
      this.form.consent = "";
      this.uploadedError = false;
      this.alreadyUploadedCode = false;
      this.winner = false;
      this.dateValue = '';
      this.timeValue = '';
      this.winType = undefined;
      this.hash = null;
      this.overEighteen = true;
      this.maximumUploads = false;
      this.winnerMessage = false;
      this.form.where_bought = "";
      this.where_bought_other = "";
      this.form.receipt = null;
      this.form.receipt2 = null;
      this.form.receipt3 = null;
      this.form.receipt4 = null;
      this.form.receipt5 = null;
      this.formIsSubmitting = false;
      this.uploadedFiles = [];
      this.$store.state.codeMessage = false;
      if (!skipFormStatusReset) {
        this.formStatus = false;
      }
    }

  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    this.codeUpperCase = this.form.code.toUpperCase();
  }
  ,
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ,
}
;
</script>

<style lang="scss"></style>

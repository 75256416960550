export default {
  data: function () {
    return {
      domain_url: process.env.VUE_APP_BASE_URL
    }
  },

  watch: {},
  computed: {},
  methods: {

    GTtrackGA4(dataLayerOptions) {
      //console.log("GTtrackGA4 track " , dataLayerOptions);
      try {
        window.dataLayer.push(dataLayerOptions);
      } catch (e) {
        console.log("not found dataLayer")
      }
    },
  }
}

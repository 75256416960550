<template>
  <div class="winner-page-main">
    <FormExpiredAndRegistered
        v-if="
        formExpired ||
        alreadyRegistered ||
        formDisabled ||
        uploadedWinner === true
      "
        :formExpired="formExpired"
        :alreadyRegistered="alreadyRegistered"
        :formDisabled="formDisabled"
        :winnerType="winnerType"
    />

    <div v-else class="winner-page__main-content">
      <div class="winnings-background position-relative pb-md-5">
        <div class="container-xl pt-3">

          <div class="row">

            <div class="product-img-wrap col-12 col-lg-4 order-2 order-lg-2 d-flex align-items-center">
              <div class="d-flex flex-column winnings-img justify-content-center align-items-center">
                <img
                    v-if="winnerType === 'fodij'"
                    class="img-fluid m-auto d-flex ml-lg-auto"
                    src="@/assets/img/winnings/nyeremeny-fodij.png"
                    alt="fődíj"
                />     
                <img
                    v-if="winnerType === 'havi'"
                    class="img-fluid order-2"
                    src="@/assets/img/winnings/nyeremeny-landing-D-havi.png"
                    alt="havi nyeremény"
                    width="300"
                />         
                <img
                    v-if="winnerType === 'heti'"
                    class="img-fluid order-2"
                    src="@/assets/img/winnings/nyeremeny-landing-D-heti.png"
                    alt="heti nyeremény"
                    width="300"
                />
                <img
                    v-if="winnerType === 'napi'"
                    class="img-fluid order-2"
                    src="@/assets/img/winnings/nyeremeny-landing-D-napi.png"
                    alt="napi nyeremény"
                    width="300"
                />
                <!--            <span class="illustration-text mt-3 mb-5 mb-lg-0">* A képek illusztrációk!</span>-->
              </div>
            </div>
            <div class="col-12 col-lg-5 offset-lg-2 order-1 order-lg-2 text-center mb-lg-5">
              <img
                  v-if="winnerType === 'fodij'"
                  class="img-fluid margin-winner-pic mb-4"
                  src="@/assets/img/design/gratulalunk.png"
                  alt="Gratulálunk!"
              />             
              <img
                  v-if="winnerType === 'heti'"
                  class="img-fluid margin-winner-pic mb-4"
                  src="@/assets/img/design/gratula-heti.png"
                  alt="Gratulálunk! Heti nyereményt nyert"
              />
              <img
                  v-if="winnerType === 'napi'"
                  class="img-fluid margin-winner-pic mb-4"
                  src="@/assets/img/design/gratula-napi.png"
                  alt="Gratulálunk! Napi nyereményt nyert"
              />
              <img
                  v-if="winnerType === 'havi'"
                  class="img-fluid margin-winner-pic mb-4"
                  src="@/assets/img/design/gratula-havi.png"
                  alt="Gratulálunk! Napi nyereményt nyert"
              />
              <!--<img
                  v-if="winnerType === 'heti'"
                  class="img-fluid margin-winner-pic d-block d-md-none"
                  src="@/assets/img/design/gratula-heti-mobile.png"
                  alt="Gratulálunk! Heti nyereményt nyert"
              />
              <img
                  v-if="winnerType === 'napi'"
                  class="img-fluid margin-winner-pic d-block d-md-none"
                  src="@/assets/img/design/gratula-napi-mobile.png"
                  alt="Gratulálunk! Napi nyereményt nyert"
              />-->
               <button class="btn btn-primary btn-primary--large d-none d-md-block mx-auto"
                        v-scroll-to="{ el: '#winnerForm', offset: -80 }">Kérem a nyereményem!
                </button>
                <p class="text-white m-0 text-center py-3" style="font-size:15px;">A részletekkel kapcsolatban email-ben fogunk keresni.</p>
            </div>
            <div class="col-12 order-3 text-center">
                <button class="btn btn-primary btn-primary--large d-block d-md-none"
                        v-scroll-to="{ el: '#winnerForm', offset: -80 }">Kérem a nyereményem!
                </button>
                <p class="real-info-txt d-block" style="background-color: transparent;">Részletes információk a <a style="color: #fff;" :href="baseUrl+'documents/Jatekszabalyzat.pdf?' +jatekszabalyTimeStamp" target="_blank">játékszabályzatban.</a><br/>A képek illusztrációk.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="codefilling-bottom-info-text position-relative d-block d-md-none">

        <p class="real-info-txt d-block d-xl-flex">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
      </div>
      <div class="codefilling-bottom-info-text position-relative d-none d-md-block">

        <p class="real-info-txt py-4">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
      </div>
      <!--      <div class="codefilling-production">-->

      <!--        <div class="container-xl">-->
      <!--          <div class="row">-->
      <!--            <ProductsThumbs/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div
          class="winner-page__codefilling-form-background second-background-inner"
          :class="{ 'congrat-background-space': uploadedWinner === true }"
      >
        <div class="pt-5 pb-5">
          <div class="container-md">

            <div class="codefilling-form border-15 codefilling-form--inner-wrap">
              <div class="winner-codefilling codefilling__form" :class="{}" style="filter: drop-shadow(0px 4px 50px #012E18);">
                <validation-observer
                    v-if="formStatus === false"
                    v-slot="{ handleSubmit }"
                    tag="div"
                >
                  <form
                      class="form form--codefilling"
                      @submit.prevent="handleSubmit(formSubmit)"
                      id="winnerForm"
                  >
                    <div class="w-100">
                      <div class="col-12">
                        <div class="winner-codefilling__form">
                          <h1 class="mb-0">Kérjük, add meg az adataidat!</h1>

                          <div class="form-group form-row">
                            <div class="surname-row input--info">
                              <div class="form-label">Vezetéknév<span class="ml-1 error">*</span></div>
                              <input
                                  v-model="form.surname"
                                  type="text"
                                  class="form-control"
                                  name="Vezetéknév"
                                  id="surname"
                                  disabled
                              />
                            </div>
                            <div class="forname-row input--info">
                              <div class="form-label">Keresztnév<span class="ml-1 error">*</span></div>
                              <input
                                  v-model="form.forename"
                                  type="text"
                                  class="form-control"
                                  name="Keresztnév"
                                  id="forename"
                                  disabled
                              />
                            </div>
                          </div>
                          <div class="form-group mb-0 form-row">
                            <div class="email-row input--info">
                              <div class="form-label">E-mail cím<span class="ml-1 error">*</span></div>
                              <input
                                  v-model="form.email"
                                  type="text"
                                  class="form-control"
                                  name="E-mail cím"
                                  id="email"
                                  placeholder="pl. tothlaszlo@mail.com"
                                  disabled
                              />
                            </div>
                            <div class="form-group input--info phone-row">
                              <ValidationProvider
                                  rules="required"
                                  v-slot="{ failedRules }"
                              >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Telefonszám, amin a futár elér."
                                    ><div class="tooltip-arrow"></div></span>
                                <div class="form-label">Telefonszám<span class="ml-1 error">*</span></div>
                                <input
                                    v-model="form.phone"
                                    type="text"
                                    class="form-control"
                                    name="Telefonszám"
                                    id="phone"
                                    placeholder="pl. +36308440145"
                                />
                                <span class="error hide">
                                  {{ getErrorMessage("phone", Object.entries(failedRules)[0]) }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="form-group mb-0 form-row">
                            <div
                                class=" form-group zip-row input--info">
                              <ValidationProvider
                                  rules="required"
                                  v-slot="{ failedRules }"
                              >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Szállítási cím irányítószáma."
                                    ><div class="tooltip-arrow"></div></span>
                                <div class="form-label">Irányítószám<span class="ml-1 error">*</span></div>
                                <input
                                    v-mask="'####'"
                                    v-model="form.zip"
                                    type="text"
                                    class="form-control"
                                    name="Irányítószám"
                                    id="zip"
                                    placeholder="pl:2021"
                                />
                                <span class="error hide">
                                  {{ getErrorMessage("zip", Object.entries(failedRules)[0]) }}
                                </span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group input--info city-row">
                              <ValidationProvider
                                  rules="required"
                                  v-slot="{ failedRules }"
                              >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Szállítási cím települése."
                                    ><div class="tooltip-arrow"></div></span>
                                <div class="form-label">Település<span class="ml-1 error">*</span></div>
                                <input
                                    v-model="form.city"
                                    type="text"
                                    class="form-control"
                                    name="Város"
                                    id="city"
                                    placeholder="pl. Pécs"
                                />
                                <span class="error">
                                  {{ getErrorMessage("city", Object.entries(failedRules)[0]) }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div
                              class="form-group mb-0 input--info form-row"
                          >
                            <div class="input--info street-row">
                              <ValidationProvider
                                  rules="required"
                                  v-slot="{ failedRules }"
                              >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Szállítási cím utcája."
                                    ><div class="tooltip-arrow"></div></span>
                                <div class="form-label">Utca<span class="ml-1 error">*</span></div>
                                <input
                                    v-model="form.street"
                                    type="text"
                                    class="form-control"
                                    name="Utca"
                                    id="street"
                                    placeholder="Minta utca"
                                />
                                <span class="error">
                                  {{ getErrorMessage("street", Object.entries(failedRules)[0]) }}
                                </span>
                              </ValidationProvider>
                            </div>
                            <div class="input--info house-row">
                              <ValidationProvider
                                  rules="required"
                                  v-slot="{ failedRules }"
                              >
                                    <span
                                        class="icon-info"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Szállítási cím házszáma."
                                    ><div class="tooltip-arrow"></div></span>
                                <div class="form-label">Házszám<span class="ml-1 error">*</span></div>
                                <input
                                    v-model="form.house"
                                    type="text"
                                    class="form-control"
                                    name="Házszám"
                                    id="house"
                                    placeholder="21"
                                />
                                <span class="error">
                                  {{ getErrorMessage("house", Object.entries(failedRules)[0]) }}
                                </span>
                              </ValidationProvider>
                            </div>
                          </div>

                          <div class="   ">
                            <div class="upload-bg-winner">
                              <div class="col-12 winner-codefilling__text d-flex justify-content-center p-0">
                                <div class="codefilling-text p-0 d-flex justify-content-center flex-column">
                                  <div class="upload-text--winner upload-text--winner--title p-0">
                                    Töltsd fel a nyitófül vagy kupak vagy kupon fotóját, hogy jól látható legyen!
                                    <span style="font-size:28px" class="ml-1 error">*</span>
                                </div>
                                  <div class="row m-auto">
                                    <div class="">
                                      <div class="img-uploads receipt-row">
                                        <label class="img-upload m-auto" for="receipt">
                                          <input
                                              type="file"
                                              id="receipt"
                                              ref="receipt"
                                              name="Nyugta"
                                              @change="handleFilesUpload()"
                                          />
                                          <div class="img-upload__text">
                                            nyitófül vagy kupak vagy kupon fotójának feltöltése
                                          </div>
                                        </label>
                                        <div
                                            class="uploaded-error error"
                                            ref="uploadederror"
                                        ></div>
                                        <div class="uploaded-files">
                                          <div class="title mt-3" v-if="uploadedFiles.length > 0">
                                            <span class="upload-text">Feltöltött fájlok:</span>
                                          </div>

                                          <div v-if="form.receipt != null">
                                            <span class="upload-text--orange">{{ form.receipt.name }}</span>
                                            <div
                                                class="delete"
                                                @click="delUploadedFile(form.receipt)"
                                            ></div>
                                          </div>
                                          <div v-if="form.receipt2 != null">
                                            <span class="upload-text--orange">{{ form.receipt2.name }}</span>
                                            <div
                                                class="delete"
                                                @click="delUploadedFile(form.receipt2)"
                                            ></div>
                                          </div>
                                          <div v-if="form.receipt3 != null">
                                            <span class="upload-text--orange">{{ form.receipt3.name }}</span>
                                            <div
                                                class="delete"
                                                @click="delUploadedFile(form.receipt3)"
                                            ></div>
                                          </div>
                                          <div v-if="form.receipt4 != null">
                                            <span class="upload-text--orange">{{ form.receipt4.name }}</span>
                                            <div
                                                class="delete"
                                                @click="delUploadedFile(form.receipt4)"
                                            ></div>
                                          </div>
                                          <div v-if="form.receipt5 != null">
                                            <span class="upload-text--orange">{{ form.receipt5.name }}</span>
                                            <div
                                                class="delete"
                                                @click="delUploadedFile(form.receipt5)"
                                            ></div>
                                          </div>
                                        </div>
                                        <span v-if="imageError" class="error text-center"
                                        >A nyitófül képének feltöltése kötelező</span
                                        >
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div>
                              </div>
                            </div>
                          </div>
                          <div class="winner-message-container">
                           
                            <div class="winner-message">
                              <div class="info">
                                <div class="icon-info--red"></div>
                                <div class="info-text">
                                  Továbbra is figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyereményed átvételével kapcsolatban keresni fogunk.</div>
                            </div>
                           </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <button type="submit" class="btn btn-modal-first">
                              adatok elküldése                              
                            </button>
                          </div>
                        </div>
                        <div>

                        </div>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EmailContact/>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import axios from "axios";
import $ from "jquery";
/* eslint-disable */

import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import FormExpiredAndRegistered from "@/components/FormExpiredAndRegistered";
import errorMessages from "@/assets/errorMessages.json";
import ProductsThumbs from "@/components/ProductsThumbs.vue"
import EmailContact from "@/components/EmailContact";

setInteractionMode("eager");
// Add a validation rules


extend("email", {
  ...email,
  message: "Az e-mail mező csak érvényes e-mail címet tartalmazhat.",
});
extend("required", {
  ...required,
  message: "{_field_} megadása kötelező.",
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "Nem elegendő karakterszám.",
});


export default {
  data() {
    return {
      form: {
        surname: "",
        forename: "",
        email: "",
        zip: "",
        city: "",
        phone: "",
        street: "",
        house: "",
        hash: this.$route.query.hash,
        hash2: this.$route.query.hash2,
        receipt: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      formStatus: false,
      alreadyRegistered: false,
      responseErrors: null,
      triedFillForm: false,
      uploadedWinner: false,
      winnerType: null,
      uploadedError: false,
      imageError: false,
      uploadedFiles: [],
      formExpired: false,
      formDisabled: false,
      formIsSubmitting: false
    };
  },
  components: {
    EmailContact,
    ValidationProvider,
    ValidationObserver,
    ProductsThumbs,
    FormExpiredAndRegistered
  },
  directives: {
    mask,
  },

  methods: {
    imageErrorHandler () {
      let _this = this;
      if (_this.form.receipt === null) {
        _this.imageError = true;
      }
    },
    handleFilesUpload() {
      let _this = this;
      //remove the error msg if there was
      this.$refs.uploadederror.innerHTML = "";

      let uploadedItem = this.$refs.receipt.files[0];

      //check file size 5Mb max
      let maxFileSize = 1024 * 1024 * 5;
      let fileSize = uploadedItem.size;

      //check file fomat
      // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

      //check file size - max 2Mb
      if (fileSize > maxFileSize) {
        this.$refs.uploadederror.innerHTML =
            "Túl nagy a fájl mérete (max. 5Mb)";
        // } else if (isGoodExtension == false) {
        //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
      } else {
        if (_this.uploadedFiles.length < 1) {
          //max 5 item could be uploaded

          let nameIndex = _this.uploadedFiles.find(
              (item) => item.name == uploadedItem.name
          );

          if (nameIndex == undefined) {
            _this.uploadedFiles.push(uploadedItem);
          } else {
            this.$refs.uploadederror.innerHTML = "Megegyező fájl név.";
          }
        } else {
          _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl.";
        }
      }

      this.fillReceipts();

      //set the default state for the input type=file
      this.$refs.receipt.value = ""

      if (this.form.receipt != null) {
        this.imageError = false;
      }
    },
    fillReceipts() {
      let _this = this;

      _this.form.receipt = null;
      _this.form.receipt2 = null;
      _this.form.receipt3 = null;
      _this.form.receipt4 = null;
      _this.form.receipt5 = null;

      this.uploadedFiles.forEach(function (item, i) {
        if (i == 0) {
          _this.form.receipt = item;
        } else if (i == 1) {
          _this.form.receipt2 = item;
        } else if (i == 2) {
          _this.form.receipt3 = item;
        } else if (i == 3) {
          _this.form.receipt4 = item;
        } else if (i == 4) {
          _this.form.receipt5 = item;
        }
      });
    },
    delUploadedFile(delItem) {
      let _this = this
      let delItemIndex = this.uploadedFiles.indexOf(delItem);

      if (delItemIndex != -1) {
        _this.uploadedFiles.splice(delItemIndex, 1);
      }

      this.$refs.uploadederror.innerHTML = "";
      this.fillReceipts();
    },
    removeError(fieldName) {
      $(`#promotionCodeForm .${fieldName}-row .error-2`).remove();
    },
    findPos(obj) {
      var curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    },

    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    // checkFileFormat(filename) {
    //   let fileName = filename;
    //   let fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

    //   console.log(filename)

    //   if (
    //       fileExtension == "jpg" ||
    //       fileExtension == "JPG" ||
    //       fileExtension == "png" ||
    //       fileExtension == "PNG"
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }

    // },
    // formSubmitTest(){
    //   this.formSubmit()
    //   this.formSubmit()
    // },
    formSubmit() {
      this.imageErrorHandler()
      var _this = this;


      // console.log('test', this.formIsSubmitting)

      if (!_this.formIsSubmitting && !_this.imageError) {
        _this.formIsSubmitting = true

        let formData = new FormData();

        /*
        Iteate over any file sent over appending the files
        to the form data.
        */
        formData.append("hash", _this.form.hash);
        formData.append("hash2", _this.form.hash2);
        formData.append("zip", _this.form.zip);
        formData.append("surname", _this.form.surname);
        formData.append("forename", _this.form.forename);
        formData.append("city", _this.form.city);
        formData.append("phone", _this.form.phone);
        formData.append("street", _this.form.street);
        formData.append("house", _this.form.house);
        formData.append("receipt", _this.form.receipt);
        formData.append("receipt2", _this.form.receipt2);
        formData.append("receipt3", _this.form.receipt3);
        formData.append("receipt4", _this.form.receipt4);
        formData.append("receipt5", _this.form.receipt5);

        axios
            .post(process.env.VUE_APP_API_URL + "index.php/step2/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              var _this = this;
              // console.log(response.data)

              if (!response.data.status) {
                let errorsArray = Object.entries(response.data.errors);

                //remove the errors divs
                if ($(".error-2").length > 0) {
                  $(".error-2").each(function () {
                    $(this).remove();
                  });
                }

                for (const [errorName, errorValue] of errorsArray) {

                  $("#winnerForm ." + errorName + "-row").append(
                      '<div class="error-2">' + errorValue + "</div>"
                  );


                  if (errorName == 'receipt' || errorName == 'receipt2' || errorName == 'receipt3' || errorName == 'receipt4' || errorName == 'receipt5') {
                    $("#promotionCodeForm .receipt-row").append(
                        '<div class="error-2">' + errorValue + "</div>"
                    );
                  }

                }
                _this.GTtrackGA4({
                  'event' : 'event',
                  'category': 'nyertes_adatbekuldes',
                  'action': 'adatbekuldes_gomb_kattintas',
                  'buttom': 'adatbekuldes',
                  'label': 'nyertes_adatbekuldes',
                  'clicked_text':'nyertes_adatbekuldes',
                  'success': 'sikertelen'
                });
              } else {
                //show the success upload user data screen
                _this.GTtrackGA4({
                  'event' : 'event',
                  'category': 'nyertes_adatbekuldes',
                  'action': 'adatbekuldes_gomb_kattintas',
                  'buttom': 'adatbekuldes',
                  'label': 'nyertes_adatbekuldes',
                  'clicked_text':'nyertes_adatbekuldes',
                  'success': 'sikeres'
                });
                _this.formStatus = true;
                _this.uploadedWinner = true;
                // scroll to success screen's top
                this.$nextTick(function () {
                  var succScreen = document.getElementById("success-data-upload");
                  succScreen.scrollIntoView(true);
                });
              }

              _this.formIsSubmitting = false
            })
            .catch(function (error) {
              console.log(error);
              _this.formIsSubmitting = false
            });
      } else {
        console.log('double form submit')
      }


    },
    getErrorMessage(fieldName, violation) {
      return violation
          ? (errorMessages[fieldName] &&
              errorMessages[fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    checkPermissions() {
      // if (this.isMobile){
      //   navigator.mediaDevices.getUserMedia({video:true})
      //   .then(function(stream) {
      //     console.log(stream)
      //   })
      //   .catch(function(err) {
      //     console.log(err)
      //   });
      // }
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  computed:{
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  created() {
    let _this = this

    $('[data-toggle="tooltip"]').tooltip();

    axios
        .get(
            process.env.VUE_APP_API_URL +
            "index.php/step2/hash?hash=" +
            this.$route.query.hash +
            "&hash2=" +
            this.$route.query.hash2
        )
        .then((response) => {
          // console.log(response.data)

          if (response.data.status === false) {
            //not winner or not good hash
            console.log('error')
            this.$router.push({name: "APCodeUpload"});
          } else if (response.data.status.toLowerCase() == "ok") {
            //a winner opened the page
            if (!response.data.winner) {
              // console.log("nem nyertes")
              this.$router.push({name: "APCodeUpload"});
            } else {
              if (response.data.winner.type.toLowerCase() === 'heti') {
                if (response.data.winner.zip != null) {
                  //if the user winner type is 'heti' --> the chose_prize will tell us that the form was filled
                  _this.formStatus = true;
                  _this.alreadyRegistered = true;
                }
              } else if (response.data.winner.type.toLowerCase() === 'fodij') {
                //if the user winner type is 'fodij' --> the zip code will tell us that the form was filled
                if (response.data.winner.zip != null) {
                  _this.formStatus = true;
                  _this.alreadyRegistered = true;
                }
              } else if (response.data.winner.type.toLowerCase() === 'napi') {
                //if the user winner type is 'fodij' --> the zip code will tell us that the form was filled
                if (response.data.winner.zip != null) {
                  _this.formStatus = true;
                  _this.alreadyRegistered = true;
                }
              }
              _this.winnerType = response.data.winner.type;
              _this.form.email = response.data.winner.email;
              _this.form.surname = response.data.winner.surname;
              _this.form.forename = response.data.winner.forename;
              //user data
              _this.form.street = response.data.winner.street
              _this.form.house = response.data.winner.house
              _this.form.phone = response.data.winner.phone
              _this.form.city = response.data.winner.city
              _this.form.zip = response.data.winner.zip
            }
          } else if (response.data.status.toLowerCase() == "expired" && response.data.winner.zip == null) {
            //the time is over than 5 days
            this.formExpired = true;
          } else if (response.data.status.toLowerCase() == "expired" && response.data.winner.zip != null) {
            //the time is over than 5 days, but sent data
            this.alreadyRegistered = true;
          } else if (response.data.status.toLowerCase() == "disabled") {
            // console.log("status disabled");
            this.formDisabled = true;
          }
        }).catch(function () {
      //the url isn't valid
      _this.$router.push({name: "APCodeUpload"});
    })
  }
};
</script>

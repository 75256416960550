<template>
  <div id="app">
    <div>
    <Header/>
    <transition name="changePage" mode="out-in">
      <router-view/>
    </transition>
    <Footer/>
    </div>
    <!--<Popup v-if="promotionIsBefore" />-->
    <AgeGateCookie/>

    <button @click="getAllLocation()" style="display:none">klikk</button>
  </div>
</template>

<script>
// import AgeGateCookie from '@/views/AgeGateCookie.vue'
import promoStatus from "@/config/promoStatus";
import Footer from "@/components/Footer";
import AgeGateCookie from "@/views/AgeGateCookie";
import Header from "@/components/Header";
//import Popup from "@/components/Popup";

export default {
  components: {
    //Popup,
    Header,
    Footer,
    AgeGateCookie
  },
  methods: {
    async geoLocation(address) {
      var ret = false
      await this.$axios('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyAfofBNAjpAnWvedWXlHd6lHZQr90JacUY').then((result) => {
        if (result.data.status == 'OK') {

          ret = {lat: result.data.results[0].geometry.location.lat, lng: result.data.results[0].geometry.location.lng}
        } else {

          ret = false
        }
      })
      return ret;
    },
    getAllLocation() {
      var _this = this;
      window.lekeres = 0;
      window.addresses = this.$addresses;
      for (var i = 0; i < this.$addresses.length && window.lekeres < 50; i++) {
        var a = this.$addresses[i];

        if (!a[10] || parseInt(a[10]) != 4) {
          //window.lekeres++;
          window.lekeres++;

          (function (i, a) {
            _this.geoLocation('Hungary ' + a[2] + " " + a[4] + " " + a[5]).then((res) => {

              if (res != false)


                window.addresses[i][8] = res.lat;
              window.addresses[i][9] = res.lng;
              window.addresses[i][10] = 4
              console.log(window.addresses[i]);
              _this.$addresses[i] = window.addresses[i]

            });
          })(i, a)
        }
      }


    }
  },
  mounted() {
    this.$store.dispatch("getSettings");
    //window.getAllLocation = this.getAllLocation;
  },
  computed: {
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    }
  },
  watch: {
    $route() {
      location.reload();
  window.dataLayer.push({
    'event': 'eventNavigation',
    'category': '',
    'action': '',
    'label': ''
  });
    },
  },
}
</script>
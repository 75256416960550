<template>
  <div class="productions main-block" id="productions-block">
    <div class="productions-inner">
        <div class="container-xl py-4 py-lg-0">
            <div class="products--txt-bg">
                <h2><span>A</span> játékban részt vevő termékek</h2>
            </div>
            <div class="col-12 pb-3">
                    <VueSlickCarousel
                            :arrows="true" 
                            :slidesToShow="5"
                            :slidesToScroll="6"
                            :initialSlide="0"
                            :responsive="respCarouselSettings"
                            :infinite="false"
                            :dots="false"
                            :draggable="true"
                        >                                            
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-1.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-2.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-3.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-4.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-5.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-6.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-7.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                            <div class="d-flex">
                                <img src="@/assets/img/products/termek-8.png" class="img-fluid" alt="heineken" draggable="false"/>
                            </div>
                        </VueSlickCarousel>
                </div>  
                <!-- <div class="col-12 text-center mt-5">
                    <a class="btn btn-modal-first" @click="setGtm('Megveszem')" :href="baseUrl+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div> -->
                <div class="col-12 text-center mt-5">
                    <p class="m-0" style="font-size:15px;">A képek csak illusztrációk.</p>
                </div>
        </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {

  data() {

    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
       respCarouselSettings:[                
                {
                "breakpoint": 992,
                    "settings": {
                        "slidesToShow": 4,
                        "slidesToScroll": 4,
                        "infinite": false,
                        "dots": false
                    }
                },
                {
                "breakpoint": 768,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                },
                {
                "breakpoint": 576,
                    "settings": {
                        "slidesToShow": 1,
                        "slidesToScroll": 1
                    }
                }
            ]
        };
    },
    components: {
        VueSlickCarousel
    },  
    computed: {
        jatekszabalyTimeStamp() {
            return Math.floor(Date.now() / 1000);
        },
    },
    name: "Carousel"
};
</script>



<template>
  <div class="winner-page__main-content ">
    <div class="email-info-message-background--padding"
    >
      <div class="expired-container-wrap">
        <!--        <div class="container-fluid expired-bg">-->
        <!--          <div class="row m-auto justify-content-center container-xl codefilling">-->
        <!--            <div class="winner-page__congrat-text winner-page__congrat-text&#45;&#45;already col-md-6 ">-->
        <!--              <img class="img-hero-small-txt" src="@/assets/img/design/HDL-1.png" alt="head image">-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="promo-live--bg">

          <div class="container-xl">
            <div class="row position-relative">

              <!--                <div class="col-12 order-hero">-->
              <!--                    <img class="img-hero-small-txt" src="@/assets/img/design/HDL-1.png" alt="head image">-->
              <!--                </div>-->

              <div class="col-12 col-xl-5 offset-xl-7 col-lg-6 offset-lg-3">
                <div class="codefilling__congrat-text">
                  <!-- aktív promóció -->

                  <div class="how-to-play-form-txt-wrap">
                     <!--<img src="@/assets/img/design/hero-heading.png">
                    <img src="@/assets/img/design/beer.png" class="d-block d-xl-none">-->
                    <div class="codefilling__text">
                      <CodeFillingText/>
                      <div class="row">
                        <div class="col-12 pb-5">
                          <!-- GAME RULES -->
                          <!--<div class="codefilling__button mt-5">
                            <button class="btn btn-primary btn-primary--large"
                                    v-scroll-to="'#codefilling-form'">játszom!
                            </button>
                            <a :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                               target="_blank" class="rules-btn-positioning"
                               type="button">Játékszabályzat
                            </a>
                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          
        </div>
        <div class="container-xl">
            <div class="row position-relative">
              <!--<div class="codefilling-bottom-info-text position-absolute d-none d-lg-block " >

                <p class="real-info-txt">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
              </div>
            </div>-->
            <!--<div class="codefilling-bottom-info-text position-relative">

              <p class="real-info-txt">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
            </div>-->
          </div>
        </div>
        <!--        <div class="codefilling-production bg-white">-->
        <!--          <div class="container-xl">-->
        <!--            <div class="row">-->
        <!--              <ProductsThumbs/>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- Már regisztrált -->
        <div
            class="expired-container second-background-inner--winner-messsage text-center form--status__content--expired"
            v-if="alreadyRegistered"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-6 offset-lg-3">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">
                    Ehhez a kódhoz már megadtad az adataidat!
                  </div>
                  <div class="codefilling-text">
                     <img class="img-fluid" src="@/assets/img/winnings/sikertelen.png"  alt="sikertelen"/> 
                    <!--<div class="text-red">
                      Mit kell tenned ezután?                    </div>-->
                  </div>
                  <div class="text-container email-info-text--small">
                    A játék ideje alatt növeld nyerési esélyedet és  tölts fel újabb kódokat!
                  </div>
                  <div class="btn-wrap mt-5">
                    <button class="btn btn-primary mb-5 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Sikeres adatfeltöltés -->
        <div id="success-data-upload"
             class="expired-container second-background-inner  text-center form--status__content--expired"
             v-if="!uploadedError && !alreadyRegistered && !formExpired && !formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-6 offset-lg-3">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">Sikeres adatfeltöltés</div>
                  <div class="codefilling-text">
                     <img class="img-fluid mb-2" src="@/assets/img/form/sikeres.png"  alt="sikeres"/> 
                    <div class="text-red">
                      Mit kell tenned ezután?                    </div>
                      
                  </div>
                  <div class="text-container email-info-text--small">
                    Továbbra is őrizd meg a nyertes nyitófület, kupakot vagy kupont a nyeremény átadásáig.<br/>
Figyeld a postafiókodat (a promóciós üzeneteket és a spamet gyűjtő mappákat is), mert a nyereménnyel kapcsolatos további részletekről ott tájékoztatunk.  <br/>
                    <div class=" pt-1">
                      <p class="text-container mt-3 mb-1 email-info-text--small">A játék ideje alatt növeld nyerési esélyedet és  tölts fel újabb kódokat!</p>
                    </div>
                  </div>
                  <div class="btn-wrap mt-4">
                    <button class="btn btn-primary mb-5 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- lejárt az idő -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formExpired"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-6 offset-lg-3">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin  margin-top-large">
                    A nyereményre való jogosultságod határideje lejárt! </div>
                  <div class="codefilling-text">
                  </div>
                  <div class="email-info-text--small text-container">
                    Sajnos a <a
                      :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                      style="text-decoration:underline; color:white; font-weight:bold"
                      target="_blank">Játékszabályzatban</a> meghatározott visszajelzési határidő lejárt, ezért annak
                    rendelkezései alapján pótnyertest sorsoltunk.<br/><br/>
                  </div>
                  <p class="form-expired-bold-text" style="font-weight:bold;color:white; margin-bottom:20px;">De ne csüggedj, a nyereményjáték végéig, 2023. augusztus 31-ig még van lehetőséged játszani.</p>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Érvénytelen pályázat -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-6 offset-lg-3">
                <div class="expired-container__inner">
                  <div class="main-h1-notwin main-h1-notwin--big  margin-top-large">Érvénytelen pályázat</div>
                  <div class="codefilling-text">
                  </div>
                  <div class="email-info-text--small text-container">
                    A <a :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                         style="text-decoration:underline; color:black; font-weight:bold" target="_blank">Játékszabályzat</a>
                    rendelkezései alapján pótnyertest sorsoltunk.<br/><br/>
                  </div>
                  <p style="font-weight:bold; margin-bottom:20px;">A nyereményjáték azonban még nem ért véget. <br/>Újabb
                    pályázatokkal 2023 augusztus 31-ig továbbra is játszhatsz a nyereményekért!</p>
                  <div class="text-container winner-form-divider--custom"></div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";
import CodeFillingText from "@/components/CodeFilling-text.vue";


export default {
  name: "FormExpiredAndRegistered",
  props: ['alreadyRegistered', 'formExpired', 'formDisabled', 'uploadedWinner', 'uploadedError',
    'winnerType'],
  components: {CodeFillingText},
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
  },
  computed: {
    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
};
</script>

<template>
    <div id="jatekmenete" class="how-to-play-form-txt">
        <div class="how-to-play-form-txt-section">
            <div class=" how-to-space-batween d-none d-lg-block">
                <p class="hero-lead mx-4"><span>Játssz a kóddal</span><br>és nyerj amszterdami utazást vagy további Heineken élményeket!</p>
            </div>
          
            <div class=" how-to-space-batween">
                <div class="how-to-play-form-txt-section-inner">
                    <div class="nr-wrap-1">
                        <!-- <div class="nr-num">
                        1
                        </div> -->
                    </div>
                    <div class="text-section">Vásárolj promóciós csomagolású Heineken® terméket vagy a résztvevő vendéglátóhelyek egyikében 2 korsó csapolt Heineken®-t ajándék kuponnal!</div>
                </div>
            </div>

          <div class=" how-to-space-batween">
            <div class="how-to-play-form-txt-section-inner">
             <div class="nr-wrap-2">
                <!-- <div class="nr-num">
                  2
                </div> -->
             </div>     
              <div class="text-section plus-padding-text">
                Töltsd fel a kupakban vagy nyitófülön vagy kuponon található kódot 2023. augusztus 31-ig!
              </div>
            </div>
          </div>

          <div class=" how-to-space-batween">
            <div class="how-to-play-form-txt-section-inner">
             <div class="nr-wrap-3">
                <!-- <div class="nr-num">
                  3
                </div> -->
             </div>
              <div class="text-section third-box-gap">Fotózd le és őrizd meg a kupakot, nyitófület vagy kupont!</div>
            </div>
          </div>
        <div class="codefilling__button">
        <button class="btn btn-primary btn-primary--large mr-lg-3 mb-3 mb-lg-0"
                v-scroll-to="{ el:'#codefilling-form', offset: -80 }">játszom!
        </button>
        <a :href="base_url+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
            target="_blank" class="rules-btn-positioning"
            type="button">Játékszabályzat
        </a>
        </div>
      </div>   
      

  </div>
</template>

<script>
export default {
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  computed:{
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  }
};
</script>

<style lang="scss"></style>

<template>
<div class="container-fluid email-contact">
  <!--<p class="text-center">Ha kérdésed van, írj nekünk a <a href="mailto:nyeremenyjatek@soproni.hu">nyeremenyjatek@soproni.hu</a> e-mail címre!</p>
  <p class="text-center">Amennyiben nem kaptad meg a válaszüzenetet, kérjük, ellenőrizd a spam mappában is!</p>-->
  <div class="row w-100">
    <div class="col-12 col-lg-3">
      <ul class="footer__list">
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/tortenetunk"
                class="footer__nav-link">
                Történetünk
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/termekeink"
                class="footer__nav-link">
                Termékeink
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/fogyaszd-felelosseggel"
                class="footer__nav-link">
                Fogyaszd felelősséggel
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/egyuttmukodesek"
                class="footer__nav-link">
                Együttműködéseink
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/kampanyok"
                class="footer__nav-link">
                Kampányok
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/cookie-iranyelv"
                class="footer__nav-link">
                Cookie irányelv
            </a>
        </li>
        </ul>
    </div>
    <div class="col-12 col-lg-3">
      <ul class="footer__list">
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/altalanos-felhasznalasi-feltetelek"
                class="footer__nav-link">
                Általános Felhasználási Feltételek
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato"
                class="footer__nav-link">
                Adatvédelmi Tájékoztató
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/gyik"
                class="footer__nav-link">
                GYIK
            </a>
        </li>
        <li class="footer__list-item">
            <a href="https://www.heineken.com/hu/hu/jatekszabalyok"
                class="footer__nav-link">
                Játékszabályok
            </a>
        </li>
        </ul>
    </div>
    <div class="col-12 col-lg-3">
      <ul class="footer__list">
         <li class="footer__list-item">
            <a href="#" target="_blank">
                Social
            </a>
        </li>
        <div class="d-flex">
            <li class="footer__list-item">
                <a href="https://www.facebook.com/heinekenhungaria/" target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook" class="footer__social-icon">
                    <img src="@/assets/img/icons/social-01.svg">
                </a>
            </li>
            <li class="footer__list-item mx-3">
                <a href="https://www.instagram.com/heineken_hu/" target="_blank"
                    rel="noopener noreferrer"
                    title="Instagram" class="footer__social-icon">
                    <img src="@/assets/img/icons/social-02.svg">
                </a>
            </li>
            <li class="footer__list-item">
                <a href="https://www.youtube.com/user/heinekenhungary" target="_blank"
                    rel="noopener noreferrer"
                    title="Youtube" class="footer__social-icon">
                    <img src="@/assets/img/icons/social-03.svg">
                </a>
            </li>
        </div>
    </ul>
    </div>
    <div class="col-12 col-lg-3">
        <ul class="footer__list">
         <li class="footer__list-item">
        <a class="footer__sticker-link">
            <img src="@/assets/img/design/enjoy-responsibly.svg" alt="Enjoy responsibly" height="32" width="32"/>
        </a>
         </li>
        </ul>
    </div>
    <div class="col-12 text-center py-5">
        <img src="@/assets/images/brand/heineken-logo.svg" alt="Enjoy responsibly" width="100"/>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "EmailContact"
}
</script>

<style scoped>

</style>
<template>
  <div class="emailInfo-container">
    <div class="container-xl">
        <div class="row">
        <div class="m-a col-12 col-lg-8 offset-lg-2 bg-white" id="contactModal" role="">
            <h2 class="text-center mb-5">
            Elakadtál?<br>Írj nekünk üzenetet!
        </h2>
            <div class="modal-txt">
            </div>
            <validationObserver
                v-if="!formStatus"
                v-slot="{ handleSubmit }"
                tag="div"
            >
            <div class="">
                <form
                    class="form form--codefilling"
                    @submit.prevent="handleSubmit(formSubmit)"
                >
                <div class="forename-row w-100 input--info">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ failedRules }"
                    >
                    <span
                        class="icon-info"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="A személyi igazolványába jegyzett név"
                    ><div class="tooltip-arrow"></div></span>
                    <div class="form-label email-row">Név<span class="ml-1 error">*</span></div>
                    <input v-model="form.name" placeholder="pl.: Kovács katalin" type="text"
                            class="form-control email-placeholder"
                            id="name">
                    <span class="error">{{
                        getErrorMessage("name", Object.entries(failedRules)[0])
                        }}</span>
                    </ValidationProvider>
                </div>
                <div class="email-row w-100 input--info">

                    <ValidationProvider
                        rules="required|email"
                        v-slot="{ failedRules }"
                    >
                    <span
                        class="icon-info mt-0 mt-md-4"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="A játékos e-mail címe"
                    ><div class="tooltip-arrow"></div></span>
                    <div class="form-label mt-4 email-row">E-mail cím<span class="ml-1 error">*</span></div>
                    <input v-model="form.email" placeholder="janka.zsadanyi@email.hu" type="text"
                            class="form-control email-placeholder"
                            id="recipient-name">
                    <span class="error">{{
                        getErrorMessage("email", Object.entries(failedRules)[0])
                        }}</span>
                    </ValidationProvider>
                </div>
                <!--              <ValidationProvider-->
                <!--                  rules="required"-->
                <!--                  v-slot="{ failedRules }"-->
                <!--              >-->
                <!--                <div class="form-label mt-4">Tárgy<span class="ml-1 error">*</span></div>-->
                <!--                <select-->
                <!--                    class="w-100 topic-row"-->
                <!--                    v-model="form.topic"-->
                <!--                    :class="{ 'select-placeholder': form.topic === '' }"-->
                <!--                >-->
                <!--                  <option value="" hidden disabled selected>Kérjük, válasszon témát</option>-->
                <!--                  <option value="adatvédelemmel kapcsolatos megkeresés" class="dropdown-item"-->
                <!--                  >adatvédelemmel kapcsolatos megkeresés-->
                <!--                  </option-->
                <!--                  >-->
                <!--                  <option value="promócióval kapcsolatos megkeresés" class="dropdown-item"-->
                <!--                  >promócióval kapcsolatos megkeresés-->
                <!--                  </option-->
                <!--                  >-->
                <!--                </select>-->
                <!--                <span class="error">{{-->
                <!--                    getErrorMessage("topic", Object.entries(failedRules)[0])-->
                <!--                  }}</span>-->
                <!--              </ValidationProvider>-->
                <ValidationProvider
                    rules="required"
                    v-slot="{ failedRules }"
                    class="message-row"
                >
                    <div class="form-label mt-4 ">Üzenet<span class="ml-1 error">*</span></div>
                    <textarea type="text" v-model="form.message" placeholder="Kérlek, ide ird az üzeneted..."
                            class="form-control textarea"
                            id="message-text">
                                </textarea>
                    <span class="error">{{
                        getErrorMessage("message", Object.entries(failedRules)[0])
                    }}</span>
                </ValidationProvider>

                <!-- <ValidationProvider
                    rules="required|acceptConditions"
                    v-slot="{ failedRules }"
                >
                    <div class="custom-control custom-checkbox condition-row">
                    <input
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        true-value="1"
                        false-value="0"
                    >
                    <label class="custom-control-label" for="customCheck1"><span>
                                <a :href="
                                baseUrl+'documents/Jatekszabalyzat.pdf?' +
                                jatekszabalyTimeStamp
                            "
                                    target="_blank">
                                <span style="text-decoration:underline">Az Adatvédelmi nyilatkozatot</span></a>
                                    megismertem és tudomásul vettem.</span></label>
                    </div>
                    <span class="error">{{
                        getErrorMessage("privacy", Object.entries(failedRules)[0])
                    }}</span>
                </ValidationProvider> -->
                <div class="custom-control custom-checkbox checkbox-margin condition-row">
                  <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules }"
                      tag="div"
                  >
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="conditions-contact"
                        name="Játékszabályzat"
                    />
                    <label
                        class="custom-control-label mb-0 condition-label"
                        for="conditions-contact"
                    >

                      <div>

                        Hozzájárulok, hogy megadott adataimat a Kapcsolatfelvétel során az adatkezelő kezelje. Bővebb információ az <a
                          href="https://www.heineken.com/hu/hu/adatvedelmi-tajekoztato"
                          target="_blank"
                      >
                        <span class="">Adatkezelési tájékoztatóban. </span></a
                      ><span
                          style="text-decoration:none; color: red;"><strong>*</strong></span>
                      </div>

                    </label>
                    <div class="mt-4"></div>
                    <span class="error">{{
                        getErrorMessage("privacy", Object.entries(failedRules)[0])
                      }}</span>
                  </ValidationProvider>
                </div>
                <div v-if="!formStatus" class="modal-btn-group ml-auto mr-auto">
                    <button type="submit" class="btn btn-modal-first " :disabled="formSubmitted">üzenet küldése</button>
                </div>
                </form>
            </div>
            </validationObserver>
            <p v-if="sendingError" class="error error--send">Sikertelen üzenet küldés</p>
            <h2 v-if="formStatus === true" class="text-center pb-2 mt-2" style="color: black">Sikeres üzenet küldés</h2>
            <button v-if="formStatus === true" type="button" class="btn btn-modal-first d-flex m-auto" data-dismiss="modal"
                    aria-label="Close" @click="defaultFormState(); formStatus = false">Vissza
            </button>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import {extend} from "vee-validate";
import axios from "axios";
import $ from "jquery";
import errorMessages from "@/assets/errorMessages.json";

extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  message: "A Játékszabályzatot kötelező elfogadnia. ",
});

export default {
  name: "emailInfo",
  mixins: [apiHandlerMI],
  data() {
    return {
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_URL,
      },
      sendingError: false,
      formStatus: false,
      form: {
        // topic: '',
        email: "",
        name: "",
        message: null,
        // condition: '0',
      },
      formSubmitted: false,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    backToMain() {
      window.location.href = "/";
    },
    removeError(fieldName) {
      $(`#contactModal .${fieldName}-row .error-2`).remove();
    },
    getErrorMessage(fieldName, violation) {
      return violation
          ? (errorMessages[fieldName] &&
              errorMessages[fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    defaultFormState() {
      let _this = this

      _this.form.name = '';
      _this.form.email = "";
      _this.form.message = null;
      // _this.form.condition = null;
      _this.formSubmitted = false
    },
    // formSubmitTest(){
    //   this.formSubmit()
    //   this.formSubmit()
    // },
    formSubmit() {

      var _this = this;

      // console.log('teszt duplicate', this.formSubmitted)

      if (!_this.formSubmitted) {
        _this.formSubmitted = true

        axios
            .post(process.env.VUE_APP_API_URL + 'message', {
              name: this.form.name,
              email: this.form.email,
              message: this.form.message,
              // condition: this.form.condition,
            })
            .then((response) => {
              // console.log(response.data)


              if (response.data.status == "error") {
                let errorsArray = Object.entries(response.data.errors);

                //remove the errors divs
                if ($("#contactModal .error-2").length > 0) {
                  $("#contactModal .error-2").each(function () {
                    $(this).remove();
                  });
                }
                for (const [errorName, errorValue] of errorsArray) {
                  $("#contactModal ." + errorName + "-row").append(
                      '<div class="error-2">' + errorValue + "</div>"
                  );
                }
              } else if (response.data.status === true) {
                //show the win or not win block instead of the codefilling form
                _this.formStatus = true;
                _this.defaultFormState()
              this.GTtrackGA4({
                'event' : 'event',
                'category': 'kapcsolati_blokk',
                'action': 'kuldes_gomb_kattintas',
                'button':'kuldes',
                'clicked_text':'kuldes',
                'action_type': 'gomb_kattintas',
                'success':'sikeres'
              });
              } else {
                $(".form-subtitle").append(
                    '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                );
                this.GTtrackGA4({
                  'event' : 'event',
                  'category': 'kapcsolati_blokk',
                  'action': 'kuldes_gomb_kattintas',
                  'button':'kuldes',
                  'clicked_text':'kuldes',
                  'action_type': 'gomb_kattintas',
                  'success':'sikertelen'
                });
              }

              _this.formSubmitted = false

            })
            .catch(function (error) {
              console.log(error);
              _this.sendingError = true
              _this.formSubmitted = false
            });

      } else {
        console.log('double form submit')
      }

    },
  },
  computed: {
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ,
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ,
};
</script>

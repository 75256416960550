<template>
    <div class="questions main-block" id="questions-block">
        <!-- <div class="question-wave-top"></div> -->
        <div class="container">
            <h2 class="text-uppercase">Kérdésed van?</h2>
            <div class="row">
                <div class="col-12 col-md-10 col-xl-10 offset-md-1 offset-xl-1">                    
                    <div class="questions-wrap">
                        <div class="questions__item" v-for="(question, index) in questions" :key="index">
                            <div @click="clickFAQ(question);setGTM(question.question)" class="question collapsed" data-toggle="collapse" :data-target="'#answer_' + index" >{{ question.question }}</div>
                            <div v-html="question.answer" class="answer collapse" :id="'answer_' + index"></div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <div class="question-paragraph">-->
<!--                        <p>-->
<!--                            Ne feledje, mindenképpen őrizze meg a blokkját, hiszen a játékosok csak ennek felmutatásával vehetik át a nyereményüket!-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="question-wave-bottom"></div>
    </div>
</template>

<script>
export default {
    methods:{
      setGTM(item){

        this.GTtrackGA4({
          'event' : 'event',
          'category': 'gyik',
          'action': 'kerdes_lenyitas',
          'label': item,
          'button': 'kerdes',
          'clicked_text':item,
          'action_type': 'kerdes_lenyitas'
        })
      },
        clickFAQ(question){
            if (question.opened == false){
                question.opened = true;
            } else {
                question.opened = false;
            }
        }
    } ,
  mounted() {
      this.$store.dispatch("getQuestions");
  },
  computed:{
    questions(){
      return this.$store.state.questions;
    },
  },
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
// import promoStatus from "@/config/promoStatus";

Vue.use(Vuex)
const timeStamp = Math.floor(Date.now() / 1000)

export default new Vuex.Store({
  state: {    
    promoStatus:'' ,
    questions: [],
    winnersAll: [],
    apiCalls: {},
    codeMessage: false,
  },
  mutations: {
    getWinners(state, response) {
      state.winnersAll = response;
    },

    setPromotionStatus(state, promoStatus) {
      state.promoStatus = promoStatus;
    },
    getQuestions(state, questions) {
      state.questions = questions;
    }
  },
  actions: {   

    getWinners({ commit }) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'step1/winners').then(response => {
          commit("getWinners", response.data.winners)
  
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getQuestions({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'questions.json?' + timeStamp).then(response => {
          commit("getQuestions", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getSettings({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'step1/status').then(response => {
          commit("setPromotionStatus",response.data.promo_status)
       
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    }
  }
})

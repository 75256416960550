<template>
  <div class="winnings main-block position-relative" id="winnings-block">
    <div class="bg-shine"></div>
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <h2>Nyeremények</h2>
        </div>
      </div>
    </div>

    <div class="background-winnings-mobil"></div>

    <div class="container-xl winnings__info">
        <div class="row">
          <div class="col-12 winnings__info--nyeremeny order-winnings mb-5 d-block d-lg-none">
          <div class="position-relative d-inline-block">
            <span class="fodij-info-bg d-lg-none" @click="infoText4 = !infoText4"></span>
            <span class="fodij-info-bg d-none d-lg-block" @mouseover="infoText4 = true"
                  @mouseleave="infoText4 = false"></span>
            <img v-show="!infoText4" class="rsp-img"
                 src="@/assets/img/winnings/nyeremeny-landing-D-fodij-mobile.png" alt="fodij "/>
            <img v-show="infoText4" class="rsp-img" src="@/assets/img/winnings/fodij-info-mobile.png" alt="fodij "/>
          </div>
        </div>
        <div class="col-12 winnings__info--nyeremeny order-winnings mb-5 d-none d-lg-block">
          <div class="position-relative d-inline-block">
            <span class="fodij-info-bg d-lg-none" @click="infoText4 = !infoText4"></span>
            <span class="fodij-info-bg d-none d-lg-block" @mouseover="infoText4 = true"
                  @mouseleave="infoText4 = false"></span>
            <img v-show="!infoText4" class="fodij-gap"
                 src="@/assets/img/winnings/nyeremeny-landing-D-fodij.png" alt="fodij "/>
            <img v-show="infoText4" class="fodij-gap" src="@/assets/img/winnings/fodij-info.png" alt="fodij "/>
          </div>
        </div>
            <div class="col-12 col-md-4  winnings__info--nyeremeny">
          <div class="position-relative d-inline-block">

          <span class="fodij-info-bg d-lg-none" @click="infoText1 = !infoText1"></span>
          <span class="fodij-info-bg d-none d-lg-block" @mouseover="infoText1 = true"
                @mouseleave="infoText1 = false"></span>
          <img v-show="!infoText1" class="rsp-img " src="@/assets/img/winnings/nyeremeny-landing-D-napi.png"
               alt="azonnali nyeremény"/>
          <img v-show="infoText1" class="rsp-img " src="@/assets/img/winnings/napi-info.png"
               alt="azonnali nyeremény"/>
          </div>
        </div>
            <div class="col-12 col-md-4  winnings__info--nyeremeny ">
          <div class="position-relative d-inline-block">
          <span class="fodij-info-bg d-lg-none" @click="infoText2 = !infoText2"></span>
          <span class="fodij-info-bg d-none d-lg-block" @mouseover="infoText2 = true"
                @mouseleave="infoText2 = false"></span>
          <img v-show="!infoText2" class="rsp-img " src="@/assets/img/winnings/nyeremeny-landing-D-heti.png" alt="heti nyeremény"/>
          <img v-show="infoText2" class="rsp-img " src="@/assets/img/winnings/heti-info.png" alt="heti nyeremény"/>
            </div>
        </div>
        <div class="col-12 col-md-4  winnings__info--nyeremeny ">
          <div class="position-relative d-inline-block">
            <span class="fodij-info-bg d-lg-none" @click="infoText3 = !infoText3"></span>
            <span class="fodij-info-bg d-none d-lg-block" @mouseover="infoText3 = true"
                  @mouseleave="infoText3 = false"></span>
            <img v-show="!infoText3" class="rsp-img " src="@/assets/img/winnings/nyeremeny-landing-D-havi.png" alt="heti nyeremény"/>
            <img v-show="infoText3" class="rsp-img " src="@/assets/img/winnings/havi-info.png" alt="heti nyeremény"/>
              </div>
          </div>
        </div>
    </div>

    <div class="container-xl winnings__not-real">
      <div class="row">
        <div class="col-12">
          <p>A képek illusztrációk!</p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  data() {
    return {
      infoText1: false,
      infoText2: false,
      infoText3: false,
      infoText4: false
    }
  },
  methods: {
    showInfoText() {
      console.log("sdlfsdf", this.infoText)
      this.infoText = !this.infoText
    }
  }

};
</script>

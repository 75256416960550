<template>
  <div class="promo-after">

    <div class="container-xl">
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-6 py-5 my-5">
            <div class="codefilling__congrat-text">
            <div class="how-to-play-form-txt-wrap ">
                <div class="how-to-play-form-txt-section">
                    <div class=" how-to-space-batween d-none d-lg-block">
                        <p class="hero-lead hero-lead--before mx-4"><span>Nyereményjátékunk 2023. augusztus 31-én éjfélkor lezárult!</span><br>Köszönjük, hogy velünk játszottál! A nyertesek listájához görgess lejjebb!</p>
                    </div>
                    <div class=" how-to-space-batween d-block d-lg-none">
                        <p class="hero-lead hero-lead--before mx-4 mb-0"><span>Nyereményjátékunk 2023. augusztus 31-én éjfélkor lezárult!</span></p>
                    </div>
                    <div class="codefilling__button d-none d-lg-flex">
                        <a :href="base_url+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                            target="_blank" class="rules-btn-positioning mb-xl-4 mr-xl-4"
                            type="button">Játékszabályzat
                        </a>
                        <button class="btn btn-primary btn-primary--large"
                                v-scroll-to="'#codefilling-form'">Nyertesek
                        </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>



export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  computed:{
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  }
};
</script>

<style lang="scss"></style>

<template>
  <div>
    <div class="promo-before position-relative">
        <div class="container-xl">
        <div class="row">
            <div class="col-12 col-lg-6 offset-lg-6">
                    <div class="codefilling__congrat-text">
                    <!-- aktív promóció -->

                    <div class="how-to-play-form-txt-wrap">
                       <div class="how-to-play-form-txt-section">
                            <div class=" how-to-space-batween">
                                <p class="hero-lead hero-lead--before mx-4"><span>Nyereményjátékunk hamarosan kezdődik!</span><br>Játssz velünk 2023. június 1. – augusztus 31. között! Töltsd fel a promóciós Heineken kupakokban és nyitófüleken található kódokat és nyerd meg a napi, heti és havi nyeremények egyikét vagy a 2 fős utat Amszterdamba!</p>
                            </div>
                        <!--<div class="codefilling__text">
                        
                        <div style="height: 300px;"></div>
                        <div class="row">
                            <div class="col-12 pb-5">-->
                            <!-- GAME RULES -->
                            <!--<div class="codefilling__button mt-5">
                                <button class="btn btn-primary btn-primary--large"
                                        v-scroll-to="'#codefilling-form'">játszom!
                                </button>
                                <a :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                                target="_blank" class="rules-btn-positioning"
                                type="button">Játékszabályzat
                                </a>
                            </div>-->
                            <!--</div>
                        </div>-->
                        </div>
                    </div>
                    </div>
                </div>

            </div>

        </div>
        </div>
  </div>
</template>

<script>


// import Carousel from "@/components/Carousel";

export default {
  // components: {Carousel},
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  
};
</script>

<style lang="scss"></style>

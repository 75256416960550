<template>
  <div>
    <div id="apCodeUpload-page">
      <div class="apCodeUpload-page-content">
        <div class="main-block codefilling padding-top-nav-plus position-relative" id="codefilling-block ">
          <div class="promo-before--bg position-relative" v-if="promotionIsBefore">
            <PromoBefore/>
            <!-- <div class="before-footer-bg"></div> -->
          </div>


          <PromoEnd v-if="promotionIsAfter"/>
          <div class="codefilling__button d-block d-lg-none mt-0 py-4" style="background: #179546;" v-if="promotionIsAfter">
            <a :href="base_url+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                target="_blank" class="rules-btn-positioning mb-4 mr-lg-4"
                type="button">Játékszabályzat
            </a>
            <button class="btn btn-primary btn-primary--large"
                    v-scroll-to="'#codefilling-form'">Nyertesek
            </button>
            </div>
          <!-- MAIN TEXT -->

          <div class="promo-live--bg" v-if="promotionIsActive">

            <div class="container-xl">
              <div class="row position-relative">

                <!--                <div class="col-12 order-hero">-->
                <!--                    <img class="img-hero-small-txt" src="@/assets/img/design/HDL-1.png" alt="head image">-->
                <!--                </div>-->


                <!--<div class="col-12 col-lg-7 order-hero">
                  <div class="head-design-cholate-img">
                    <img class="w-100" src="@/assets/img/design/hero-products.png" alt="product image">

                  </div>
                  <div class="head-design-cholate-img-sm">
                    <img class="img-hero-small w-100" src="@/assets/img/design/hero-products.png" alt="product image">
                  </div>

                </div>-->

                <div class="col-12 col-xl-5 offset-xl-7 col-lg-6 offset-lg-3">
                  <div class="codefilling__congrat-text">
                    <!-- aktív promóció -->

                    <div class="how-to-play-form-txt-wrap" v-if="promotionIsActive">
                      <div class="how-to-play-form-txt-section d-block d-lg-none">
                        <div class=" how-to-space-batween">
                            <p class="hero-lead mx-2"><span>Játssz a kóddal</span><br>és nyerj amszterdami utazást vagy további Heineken élményeket!</p>
                        </div>
                      </div>
                      <div class="codefilling__text d-lg-block d-none">
                        <CodeFillingText v-if="promotionIsActive"/>
                        <div class="row">
                          <div class="col">
                            <!-- GAME RULES -->
                           <!-- <div class="codefilling__button" :class="{'promoEnd': !promotionIsActive}">
                              <button v-if="promotionIsActive" class="btn btn-primary btn-primary--large"
                                      v-scroll-to="'#codefilling-form'"   @click = "setGTM('jatszom_gomb')">játszom!
                              </button>
                              <a :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                                 target="_blank" class="rules-btn-positioning"
                                 @click = "setGTM('jatekszabalyzat_gomb')"
                                 type="button">Játékszabályzat
                              </a>
                            </div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="codefilling-bottom-info-text d-block d-lg-none " v-if="promotionIsActive">
              <p class="real-info-txt">Görgess lejjebb a részletekért!</p>
            </div>
          </div>
          <div class="codefilling-bottom-info-text p-3 d-none d-lg-block" style="background: #E21F26;">
                <p class="real-info-txt container-xl">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
          </div>
          <div class="how-to-play-form-txt-wrap d-lg-none d-block" v-if="promotionIsActive">
            <div class="codefilling__text">
              <CodeFillingText v-if="promotionIsActive"/>
<!--              <div class="row  ">-->
<!--                <div class="col">-->
                  <!-- GAME RULES -->
                  <!--<div class="codefilling__button pb-4 pb-lg-0 " :class="{'promoEnd': !promotionIsActive}">
                    <button v-if="promotionIsActive" class="btn btn-primary btn-primary--large btn-howto-mobile mt-4 mt-lg-0 mb-4 mb-lg-0"
                            v-scroll-to="'#codefilling-form'"  @click = "setGTM('jatszom_gomb')">játszom!
                    </button>
                    <a :href="baseUrl+'documents/Jatekszabalyzat.pdf?' + jatekszabalyTimeStamp"
                       target="_blank" class="rules-btn-positioning rules-btn-positioning--mobile"
                       type="button"  @click = "setGTM('jatekszabalyzat_gomb')">Játékszabályzat
                    </a>
                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <div class="codefilling-bottom-info-text p-3 d-block d-lg-none" style="background: #E21F26;">
                <p class="real-info-txt container-xl">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
          </div>
        </div>
        <!--<div class="codefilling-bottom-info-text position-relative d-block d-lg-none padding" v-if="promotionIsActive">

          <p class="real-info-txt d-block h-100">A promóció időtartama: 2023. 06. 01. – 08. 31. A promócióban csak 18 éven felüliek vehetnek részt. <br>A nyitófület, kupakot vagy kupont a nyereményjáték lezárulta után további 2 hónapig (2023. október 30-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!</p>
        </div>-->
        
        <!--        <div class="codefilling-production" v-if="promotionIsActive || promotionIsAfter">-->
        <!--          <ProductsThumbs/>-->
        <!--          <div class="promo-after-gap-thumbs" v-if="promotionIsAfter"></div>-->
        <!--        </div>-->

        <div id="form" class="second-background" v-if="promotionIsActive">
          <div class="second-background-inner" style="background: linear-gradient(360deg, #179546 11.81%, #101417 83.95%);">
            <div class="container-xl">
              <div class="codefilling__form">
                <CodeFillingForm/>
              </div>
            </div>
          </div>
          <!--<Map id="bevaltohelyek" :addresses="this.$addresses"/>-->
        </div>
        <Winnings id="nyeremenyek" v-if="promotionIsActive || promotionIsAfter"/>
        <div class="background-main-winners" v-if="promotionIsAfter">
          <Winners id="nyertesek" v-if="promotionIsAfter"/>
        </div>

        <div class="only-mob-background-win-pro">
            <Productions v-if="promotionIsActive"/>
        </div>


        <!--        <div class="codefilling-production" v-if="promotionIsActive">-->
        <!--          <ProductsThumbs/>-->
        <!--        </div>-->
        <div class="background-main-winners">

          <Winners id="nyertesek" v-if="promotionIsActive && !$store.state.codeMessage"/>
        </div>
        <Questions v-if="promotionIsActive"/>
        <div class="background-email-info " v-if="promotionIsActive">

          <EmailInfo id="gyik" v-if="!$store.state.codeMessage"/>
        </div>
      </div>
      <!--<Map id="bevaltohelyek" :addresses="this.$addresses" v-if="promotionIsAfter"/>-->
      <EmailContact/>
    </div>

    <!-- <Footer/> -->
  </div>

</template>


<script>
//import Map from '@/components/Map.vue'
import CodeFillingForm from "@/components/CodeFilling-form.vue";
import CodeFillingText from "@/components/CodeFilling-text.vue";
import Winnings from "@/components/Winnings.vue";
import Productions from "@/components/Productions.vue";
import Winners from '@/components/Winners.vue';
import promoStatus from "@/config/promoStatus";
import EmailInfo from "@/components/emailInfo";
import PromoBefore from "@/components/CodeFilling-text_promoBefore";
import PromoEnd from "@/components/CodeFilling-text_promoEnd";
// import ProductsThumbs from "@/components/ProductsThumbs.vue"
// import Footer from "@/components/Footer.vue"
import $ from "jquery";
import Questions from "@/components/Questions";
import EmailContact from "@/components/EmailContact";
// import axios from "axios";

export default {
  data() {
    return {
      alreadyRegistered: false,
      formExpired: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      backgroundChanger: false,
    };
  },
  mounted() {
    this.$store.dispatch("getSettings");
  },
  methods: {
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'jatekleiras',
        'action': 'gomb_kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'gomb_kattintas'
      })
    },
  },
  computed: {
    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
  components: {
    EmailContact,
    Questions,
    EmailInfo,
    CodeFillingForm,
    CodeFillingText,
    Winnings,
    Productions,
    Winners,
    PromoBefore,
    PromoEnd,
    // ProductsThumbs,
    // Footer,
    //Map
  },
  created() {
    $('[data-toggle="tooltip"]').tooltip();

  }
};
</script>

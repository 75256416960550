import Vue from 'vue'
import VueRouter from 'vue-router'

import APCodeUpload from '@/views/APCodeUpload.vue'
import Winner from '@/views/Winner.vue'
// import Impressum from '@/views/Impressum.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'APCodeUpload',
    component: APCodeUpload
  },
  {
    path: '/nyeremeny',
    name: 'Winner',
    component: Winner
  },  
  // {
  //   path: '/impresszum',
  //   name: 'Impressum',
  //   component: Impressum
  // },  
  {    
    path: "*",
    redirect: '/'         
  }    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {  
    return { x: 0, y: 0 }
  }
})


// const router = new VueRouter({
//   routes
// })


export default router

<template>
  <div class="winners main-block" id="winners-block">
    <div class="container-xl">
      <div class="row">
        <div class="col-12 ">
          <div class="winners-block__inner">

            <h2 class="promo-live-h2" >Koccintsunk a nyertesekre!</h2>
          
            <div class="winners-button-select winners-button-select--desctop" >
              <button :class="{'active':winnerState === 'napi'}" v-on:click="setWinnerState('napi');setGTM('nyertesek_napi')">napi nyertesek</button>
              <div class="vertical-line-btn"></div>
              <button :class="{'active':winnerState === 'heti'}" v-on:click="setWinnerState('heti');setGTM('nyertesek_heti')">heti nyertesek
              </button>
              <div class="vertical-line-btn"></div>
              <button :class="{'active':winnerState === 'havi'}" v-on:click="setWinnerState('havi');setGTM('nyertesek_havi')">havi nyertesek
              </button>
              <div class="vertical-line-btn"></div>
              <button :class="{'active':winnerState === 'fodij'}" v-on:click="setWinnerState('fodij');setGTM('nyertesek_fodij')">fődíj nyertes
              </button>
            </div>

            <div class="dropdown winners-button-select--mobile">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <span v-if="winnerState == 'fodij'">Fődíj</span>
                  <span v-else>{{winnerState}} &nbsp;</span>
                  <span>Nyertesek</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="dropdown-item" v-on:click="setWinnerState('napi')">Napi nyertesek</div>
                <div class="dropdown-item" v-on:click="setWinnerState('heti');setGTM('nyertesek_heti')">Heti nyertesek</div>
                <div class="dropdown-item" v-on:click="setWinnerState('havi');setGTM('nyertesek_havi')">Havi nyertesek</div>
                <div class="dropdown-item" v-on:click="setWinnerState('fodij');setGTM('nyertesek_fodij')">Fődíj nyertes</div>
              </div>
            </div>
            
<!--            <div class="winners-underline-small"></div>-->

<!--            <div class="winners-underline"></div>-->

            <div class="winners-wrap mt-3 mt-md-5">
              <div class="winners__item">

                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block "  >
                  <thead>
                  <tr class="winners-top">
<!--                    <th>Sorszám</th>-->
                    <th>Nyertes neve</th>
                    <th>Típus</th>
                    <th class="winners-id sorszam-center">Nyeremény</th>
              
                  </tr>
                  </thead>
                  <tbody v-if="filteredWinners && filteredWinners.length">
                      <tr class="gutter-td">
                        <td colspan="3"></td>
                      </tr>
                      <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                        
                        <td data-title="Nyertes neve" class="winners-id"><span><strong>{{ winner.name }}</strong></span> </td>

                        <td data-title="típus" class="winner-type-td">
                          <span v-if="winner.type == 'fodij'">fődíj </span>
                          <span v-else>{{winner.type}} </span>
                          <span class=" p-0">nyertes</span>
                        </td>
                        <td data-title="nyeremény" class="winners-product">
                          <span class="text-uppercase" v-if="winner.type === 'napi'"><strong>2 db Budapest Park koncertjegy</strong></span>                          
                          
                          <span class="text-uppercase" v-if="winner.type === 'fodij'"><strong>2 fős amszterdami utazás</strong></span>                          
                          <span class="text-uppercase" v-if="winner.type === 'heti'"><strong>1 db Heineken partycsomag</strong></span>
                          <span class="text-uppercase" v-if="winner.type === 'havi'"><strong>1 db Heineken élménycsomag</strong></span>
                        </td>

                      </tr>
                      <tr class="gutter-td">
                        <td colspan="4"></td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td v-if="winnerState != 'fodij'" class="no-winner" colspan="4">Nemsokára kiderül, kik lettek a nyerteseink!</td>
                      <td v-if="winnerState == 'fodij'" class="no-winner" colspan="4">Nemsokára kiderül, ki lett a nyertesünk!</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="d-flex justify-content-center pt-4" v-if="needMoreBtn">
              <button target="_blank" type="button" class="rules-btn-white" v-on:click="moreWinners(true)">Mutasd a korábbi nyerteseket</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #E21F26;">
      <div class="container-xl">
          <p class="winners-remind-txt mb-0 pl-2 pr-2">
            Ne feledd, mindenképpen őrizd meg a nyitófület vagy kupakot vagy kupont, hiszen a játékosok csak ennek felmutatásával vehetik át a nyereményüket!
          </p>
      </div>
    </div>
  </div>
</template>

<script>
import promoStatus from "@/config/promoStatus";

export default {
  data() {
    return {
      winnerState: "heti",
      winnerTypes: {
        napi: "Napi nyertes",
        heti: "Heti nyertes",
        havi: "Havi nyertes",
        fodij: 'Fődíj nyertes'
      },
      needMoreBtn: false,
      needMoreWinners: false
    }
  },
  computed: {

    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    filteredWinners() {
        let result;
        
        if(this.$store.state.winnersAll ?? []){
            result = this.$store.state.winnersAll.filter(w => w.type === this.winnerState)

            if(result.length > 10 && !this.needMoreWinners){
                this.checkBtn(true);
                return result.slice(0,10);
            }else{
                this.checkBtn(false);
                return result;
            }
        }
        this.checkBtn(false);
        return result;
    }
  },
  mounted() {
    this.$store.dispatch("getWinners");
  },
  components: {},
  methods: {
    setGTM(item){

      this.GTtrackGA4({
        'event' : 'event',
        'category': 'nyertesek',
        'action': 'kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'nyertesek_kattintas'
      })
    },
    setWinnerState(newState) {
      this.winnerState = newState;
      this.moreWinners(false);
      
    },
    checkBtn(newState) {
      this.needMoreBtn = newState;
    },
    moreWinners(newState){
        this.needMoreWinners = newState;
    }
  }
}
</script>
<template>
  <b-navbar toggleable="lg" sticky>
    <b-navbar-brand href="https://www.heineken.com/hu/hu/home">
      <img src="logo.svg" alt="heineken">
    </b-navbar-brand>
    <b-navbar-toggle @click="isOpen=!isOpen; setGTM('menu_gomb')" target="nav-collapse">
      <div
          class="custom-hamburger"
          :class="{ 'custom-hamburger-close' : isOpen }">
        <span></span>
        <span></span>
        <span></span>
      </div>

    </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item>
          Heineken 150
        </b-nav-item>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Promóció              
            </a>            
            <div v-if="$route.name != 'Winner'" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a 
              class="dropdown-item" href 
              @click="isOpen=false;setGTM(item.name)"
              v-for="item in items" :key="'item' + item.id"
              v-scroll-to="{ el: item.link, offset: -80 }">{{item.name}}</a>
            </div>
            <div v-else class="dropdown-menu" > 
              <a class="dropdown-item" :href="domainUrl + item.link"
              @click="isOpen=false;setGTM(item.name)"
              v-for="item in items" :key="'item' + item.id"
              >{{item.name}}</a>
            </div>
        </li>
        <!-- <b-nav-item>
          Képfeltöltés
        </b-nav-item> -->

      </b-navbar-nav>

      <!-- Right aligned nav items -->

    </b-collapse>
  </b-navbar>

</template>

<script>
export default {
  data() {
    return {
      domainUrl: process.env.VUE_APP_BASE_DOMAiN,
      isOpen: false,
      items: [
        {
          'id': 1,
          'name': 'Játék menete',
          'link': '#jatekmenete'
        },
        {
          'id': 2,
          'name': 'Kódfeltöltés',
          'link': '#form'
        },
        {
          'id': 3,
          'name': 'Nyeremények',
          'link': '#nyeremenyek'
        },
        /*{
          'id': 4,
          'name': 'Beváltóhelyek',
          'link': '#bevaltohelyek'
        },*/
        {
          'id': 5,
          'name': 'Nyertesek',
          'link': '#nyertesek'
        },
        {
          'id': 6,
          'name': 'GYIK',
          'link': '#questions-block'
        }
      ]
    }
  },
  methods: {
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'fejlec',
        'action': 'kattintas',
        'label': item,
        'menu': 'fejlec',
        'clicked_text':item,
        'action_type': 'menu_kattintas'
      })
    },
  }
}
</script>